<template>
  <page-title class="q-mb-lg" :title="$t('Desativar conta')" />

  <q-intersection
    class="full-height full-height-intersection"
    transition="slide-up"
  >
    <q-card
      class="default-rounded full-height default-shadow"
      :class="{ 'q-mb-lg': $q.platform.is.mobile }"
    >
      <q-ajax-bar
        ref="bar"
        position="bottom"
        color="blue"
        size="10px"
        skip-hijack
      />
      <q-card-section>
        <q-item-label class="q-mt-md text-weight-medium"
          >Lamentamos profundamente que tenha que ir embora, vamos trabalhar
          mais para que no futuro você possa vir escolher ficar com a
          gente!</q-item-label
        >
        <div class="default-rounded q-mt-md q-pa-sm">
          <p class="text-weight-medium text-uppercase text-red text-h6">
            Algumas considerações
          </p>
        </div>

        <q-list dense bordered padding class="rounded-borders">
          <q-item :active="active">
            <q-item-section avatar>
              <q-icon name="check" />
            </q-item-section>
            <q-item-section>
              Bonificações de novas vendas não serão mais
              geradas</q-item-section
            >
          </q-item>
          <q-item :active="active">
            <q-item-section avatar>
              <q-icon name="check" />
            </q-item-section>
            <q-item-section>
              Você não receberá mais bonificações</q-item-section
            >
          </q-item>
          <q-item :active="active">
            <q-item-section avatar>
              <q-icon name="check" />
            </q-item-section>
            <q-item-section>
              Sua loja no e-commerce não ficará mais acessível</q-item-section
            >
          </q-item>
        </q-list>

        <div class="bg-grey-4 q-pa-md default-rounded q-mt-md">
          Não lembra sua senha? entre em contato com o e-mail
          <b>suporte@flip.net.br</b> e solicite a desativação da sua conta.
        </div>
      </q-card-section>
      <q-card-section class="q-pa-none">
        <Form
          class="q-gutter-md"
          :initial-values="initialValues"
          :validation-schema="schema"
          @submit="onSubmit"
        >
          <q-card-section class="row items-center">
            <div class="col-12">
              <Field
                v-for="(structure, key) in structureForm"
                :key="key"
                :name="structure.name"
                v-slot="{ errorMessage, value, field }"
              >
                <q-input
                  class="q-mr-sm width-fit-content-flex"
                  filled
                  bottom-slots
                  clearable
                  :stack-label="structure[' stack-label']"
                  v-bind="field"
                  :type="structure.type"
                  :counter="!!structure.maxlength"
                  :maxlength="structure.maxlength"
                  :model-value="value"
                  :label="structure.label"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                >
                  <template v-slot:before>
                    <q-icon :name="structure.icon" />
                  </template>
                </q-input>
              </Field>
            </div>

            <q-btn
              color="black"
              type="submit"
              class="full-width q-mt-md"
              :label="$t('login.recover.submit')"
              :loading="in_request"
              :disable="in_request"
            />
          </q-card-section>
        </Form>
      </q-card-section>
    </q-card>
  </q-intersection>
</template>

<script>
import {
  labels,
  getStructure,
  build,
  getMappedFields,
} from "@/shared/form-presets";
import PageTitle from "@/modules/main/components/page-title";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyError } from "@/shared/helpers/notify";
import { Field, Form } from "vee-validate";
import { ref } from "vue";

const { useActions } = createNamespacedHelpers("account");

export default {
  name: "DisableAccount",

  components: {
    Field,
    Form,
    PageTitle,
  },

  setup() {
    const in_request = ref(false),
      { disableAccount } = useActions({
        disableAccount: "disableAccount",
      }),
      fieldsDefinitions = ["passwordValidation"],
      structureForm = getStructure(fieldsDefinitions),
      schema = build(getMappedFields(fieldsDefinitions)),
      initialValues = {};

    const onSubmit = () => {
      in_request.value = true;
      disableAccount()
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          in_request.value = false;
          notifyError("notify.unprocessed_error_on_delete");
        });
    };

    return {
      in_request,
      labels,
      schema,
      initialValues,
      structureForm,
      onSubmit,
    };
  },
};
</script>

<style scoped>
.m-negative {
  margin-top: -20px;
}
</style>
